// @ts-nocheck

import React, { useEffect, useState } from 'react'
import { firestore } from '../../firebase'
import DataTable from '../DataTable/DataTable'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Files.scss'
import Skeleton from '../Skeleton/Skeleton'

interface FileLink {
  id: string
  title: string
  downloadURL: string
  date: Date
  fileName: string
  password: string
  uploader: {
    name: string
  }
}

const Files = () => {
  const [files, setFiles] = useState<FileLink[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    firestore()
      .collection('links')
      .orderBy('created', 'desc')
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          setFiles((currentFiles) => [
            ...currentFiles,
            {
              id: doc.id,
              title: doc.data().title,
              downloadURL: doc.data().downloadURL,
              fileName: doc.data().fileName,
              date: new Date(doc.data().created.seconds * 1000),
              password: doc.data().password,
              uploader: {
                name: doc.data().uploader.name,
              },
            },
          ])
        })
        setLoading(false)
      })
  }, [])

  return (
    <div>
      <h1>
        <span className='u-t-primary'></span> i40 Transfer | Files
      </h1>
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>File name</th>
              <th>Created</th>
              <th>Uploader</th>
              <th style={{ textAlign: 'right' }}>Copy link</th>
            </tr>
          </thead>
          <tbody>
            {files.map((link) => (
              <FileItem key={link.id} link={link} />
              // <li key={link.id}>
              //   <a
              //     href={`https://transfer.i40.de/get/${link.id}`}
              //     target='_blank'
              //     rel='noopener noreferrer'>
              //     {link.title} – {link.fileName} – {link.date.toLocaleString()} – (
              //     {link.uploader.name})
              //   </a>
              // </li>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

const FileItem = ({
  link: {
    id,
    title,
    fileName,
    password,
    date,
    uploader: { name },
  },
}) => {
  const clipboardHandler = () => {
    const downloadURL = `https://transfer.i40.de/get/${id}`
    if (typeof navigator.clipboard === 'undefined') return
    navigator.clipboard.writeText(downloadURL)
    toast.success('Copied to clipboard')
    console.log(`Copied to clipboard: ${downloadURL}`)
  }
  return (
    <tr>
      <td>
        {password && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            style={{ zoom: '0.6', marginRight: '0.5rem' }}>
            <path
              fill='#fff'
              d='M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10 0v-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8z'
            />
          </svg>
        )}

        {title}
      </td>
      <td>{fileName}</td>
      <td>{date.toLocaleString()}</td>
      <td>{name}</td>
      <td style={{ textAlign: 'right', zoom: '0.6' }}>
        <svg
          onClick={clipboardHandler}
          style={{ cursor: 'pointer' }}
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'>
          <path
            fill='#fff'
            d='M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z'
          />
        </svg>
      </td>
    </tr>
  )
}

export default Files
