import { NONAME } from 'dns'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { storage, firestore } from '../../firebase'

const Download = () => {
  const { fileID } = useParams()
  const [title, setTitle] = useState('Loading …')
  const [loading, setLoading] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const [storageRef, setStorageRef] = useState('')
  const [fileName, setFileName] = useState('')
  const [password, setPassword] = useState('')
  const [userPassword, setUserPassword] = useState('')

  const downloadIndicator = useRef<HTMLDivElement>(null)

  useEffect(() => {
    async function getDocument() {
      const ref = await firestore().collection('links').doc(fileID).get()
      const doc = await ref.data()

      if (doc?.title) {
        setTitle(doc.title)
        setFileName(doc.fileName)
        setPassword(doc.password)
        setLoading(false)
        if (doc?.storageReference) setStorageRef(doc.storageReference)
        return
      }

      setTitle('File does not exist')
      return setLoading(false)
    }
    getDocument()
  }, [])

  const handleDownload = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault()
    storage()
      .ref(storageRef)
      .getDownloadURL()
      .then((url) => {
        if (password !== userPassword) return alert('Please enter the password')
        setIsDownloading(true)
        const xhr = new XMLHttpRequest()
        xhr.responseType = 'blob'
        xhr.onload = (event) => {
          const blob = xhr.response
          const a = document.createElement('a')
          a.style.display = 'none'
          document.body.appendChild(a)
          const downloadURL = window.URL.createObjectURL(blob)
          a.href = downloadURL
          a.download = fileName
          //programatically click the link to trigger the download
          a.click()
          //release the reference to the file by revoking the Object URL
          window.URL.revokeObjectURL(downloadURL)
        }
        xhr.onprogress = (event) => {
          console.log(event.loaded, event.total, event.loaded / event.total)
          downloadIndicator.current!.style.background = `linear-gradient(90deg, green, green ${
            (event.loaded / event.total) * 100
          }%, transparent 0%)`
        }
        xhr.onloadend = () => {
          setIsDownloading(false)
        }
        xhr.onerror = () => {
          setIsDownloading(false)
        }
        xhr.open('GET', url)
        xhr.send()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      {' '}
      <div className="background">
        {/* <img src='https://source.unsplash.com/mawU2PoJWfU/1920x1080' /> */}
        {/* <img src="https://source.unsplash.com/random/1920x1080" /> */}
        <img src="https://picsum.photos/1920/1080" />
        {/* <img src='/transfer-bg.jpg' /> */}
      </div>
      <div className="login-main-container">
        <div className="login-container">
          <div className="login-logo">
            <img src="/Final-i40-Logo-Full-Name-White.svg" alt="" />
          </div>
          <h1 className="login-heading">Transfer</h1>
          <p style={{ textAlign: 'center', fontWeight: 'bold' }}>File</p>
          <p style={{ textAlign: 'center', overflowWrap: 'break-word' }}>
            {title}
          </p>
          <form onSubmit={handleDownload}>
            {password && (
              <input
                style={{
                  marginTop: '1rem',
                  borderRadius: '0.5rem',
                  border: 'none',
                  textAlign: 'center',
                }}
                placeholder="Enter password to download"
                type="password"
                onChange={(e) => setUserPassword(e.target.value)}
              />
            )}
            {storageRef && !isDownloading && (
              <button
                className="login-btn"
                style={{ marginTop: '1rem' }}
                type="submit"
              >
                Download
              </button>
            )}
            {isDownloading && (
              <div
                ref={downloadIndicator}
                className="download-indicator"
                style={{ marginTop: '1rem' }}
              >
                File is downloading …
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

export default Download
