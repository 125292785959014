// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory, Link } from 'react-router-dom'

import './Login.scss'

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { currentUser, login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()

    login()
    console.log()

    // try {
    //   setError('')
    //   // setLoading(true)
    //   // await login(emailRef.current.value, passwordRef.current.value)
    //   await login()
    //   console.log('logged in')
    //   history.push('/')
    // } catch {
    //   setError('Failed to log in')
    // }

    setLoading(false)
  }

  useEffect(() => {
    if (currentUser) history.push('/')
  }, [currentUser])

  return (
    <>
      <div className="background">
        {/* <img src='https://source.unsplash.com/mawU2PoJWfU/1920x1080' /> */}
        {/* <img src='https://source.unsplash.com/random/1920x1080' /> */}
        <img src="https://picsum.photos/1920/1080" />
        {/* <img src='transfer-bg.jpg' /> */}
      </div>
      <div className="login-main-container">
        <div className="login-container">
          <div className="login-logo">
            <img src="Final-i40-Logo-Full-Name-White.svg" alt="" />
          </div>
          <h1 className="login-heading">Transfer</h1>
          <button className="login-btn" onClick={() => login()}>
            Login
          </button>
          {/* <div>
            <h2 className='text-center mb-4'>Log In</h2>
            {error && <div variant='danger'>{error}</div>}
            <form onSubmit={handleSubmit}>
              <div id='email'>
                <label>Email</label>
                <input type='email' ref={emailRef} />
              </div>
              <div id='password'>
                <label>Password</label>
                <input type='password' ref={passwordRef} />
              </div>
              <button disabled={loading} className='w-100' type='submit'>
                Log In
              </button>
            </form>
          </div> */}
        </div>
      </div>
    </>
  )
}
