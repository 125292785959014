import React from 'react'
import { Link } from 'react-router-dom'

import './Navbar.scss'
import { useAuth } from '../../contexts/AuthContext'
import { useLocation } from 'react-router-dom'

const Navbar = () => {
  // @ts-ignore
  const { currentUser, logout } = useAuth()
  const location = useLocation()

  if (!currentUser) return <></>
  if (location.pathname.startsWith('/get/')) return <></>

  return (
    <nav className='navbar'>
      <ul className='navbar-nav'>
        <li className='logo'>
          <div className='nav-link'>
            <span className='link-text logo-text'>i40</span>
            <img
              src='/Final-i40-Logo-Color-cropped-square.svg'
              alt='i40 Logo'
            />
          </div>
        </li>

        <li className='nav-item'>
          <Link to='/' className='nav-link'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'>
              <path
                fill='currentColor'
                d='M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z'
              />
            </svg>
            <span className='link-text'>Upload</span>
          </Link>
        </li>
        <Link to='/files' className='nav-item'>
          <div className='nav-link'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'>
              <path
                fill='currentColor'
                d='M11 5c-1.629 0-2.305-1.058-4-3h-7v20h24v-17h-13z'
              />
            </svg>
            <span className='link-text'>Files</span>
          </div>
        </Link>
        {/* <li className='nav-item'>
          <div className='nav-link'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'>
              <path
                fill='currentColor'
                d='M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z'
              />
            </svg>
            <span className='link-text'>Settings</span>
          </div>
        </li> */}
        <li className='nav-item'>
          <div
            className='nav-link'
            onClick={() => logout()}
            style={{ cursor: 'pointer' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'>
              <path
                fill='currentColor'
                d='M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z'
              />
            </svg>{' '}
            <span className='link-text'>Settings</span>
          </div>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
