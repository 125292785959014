import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
// import 'firebase/analytics'
// import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyAJf4mAMlQQ-h_tKjpdb5NTYmJAvlhetTw',
  authDomain: 'i40-transfer.firebaseapp.com',
  databaseURL: 'https://i40-transfer.firebaseio.com',
  projectId: 'i40-transfer',
  storageBucket: 'storage.transfer.i40.de',
  messagingSenderId: '602117458510',
  appId: '1:602117458510:web:97027c554aeb838891111f',
  measurementId: 'G-BEM6VWLXYX',
}

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const firestore = firebase.firestore
export const storage = firebase.storage
// export const analytics = firebase.analytics()
// export const functions = firebase.functions()
