import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Navbar from './components/Navbar/Navbar'
import UploadForm from './components/UploadForm/UploadForm'
import Files from './components/Files/Files'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from './routes/PrivateRoute'
import Login from './components/Login/Login'
import Download from './components/Download/Download'

const context = React.createContext<AppContextInterface | null>(null)

export const AppContextProvider = context.Provider
export const AppContextConsumer = context.Consumer
export const useAppContext = () => React.useContext(context)

type AppContextInterface = {
  title: string
  setTitle: (title: string) => void
  password: string
  setPassword: (password: string) => void
}

function App() {
  const [title, setTitle] = useState('')
  const [password, setPassword] = useState('')

  return (
    <Router>
      <AuthProvider>
        <div className='App'>
          <AppContextProvider
            value={{ title, setTitle, password, setPassword }}>
            <Navbar />
            <main>
              <Switch>
                <PrivateRoute exact path='/' component={UploadForm} />
                <PrivateRoute path='/files' component={Files} />
                <Route path='/login' component={Login} />
                <Route path='/get/:fileID' component={Download} />
              </Switch>
            </main>
            <ToastContainer position='top-center' />
          </AppContextProvider>
        </div>
      </AuthProvider>
    </Router>
  )
}

export default App
