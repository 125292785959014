import React, { useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'

//@ts-ignore
const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

// @ts-ignore
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const provider = new firebase.auth.OAuthProvider('microsoft.com')

  provider.setCustomParameters({
    tenant: 'a2b37baf-eab9-4ae3-b19c-bad43f68b580',
  })

  function login() {
    console.log(auth.currentUser)
    return auth
      .signInWithPopup(provider)
      .then((result) => {
        console.log('IdP data', result)
        const credential: firebase.auth.OAuthCredential | null =
          result.credential

        const accessToken = credential?.accessToken
        const idToken = credential?.idToken
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function logout() {
    return auth.signOut()
  }

  // function resetPassword(email) {
  //   return auth.sendPasswordResetEmail(email)
  // }

  // function updateEmail(email) {
  //   return currentUser.updateEmail(email)
  // }

  // function updatePassword(password) {
  //   return currentUser.updatePassword(password)
  // }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // @ts-ignore
      setCurrentUser(user)
      // history.push('/')
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    // signup,
    logout,
    // resetPassword,
    // updateEmail,
    // updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
