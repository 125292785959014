import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../App'
import './InputField.scss'

type InputFieldProps = {
  name: string
}

const InputField = ({ name }: InputFieldProps) => {
  const { title, setTitle, password, setPassword } = useAppContext()!

  let value = ''
  switch (name) {
    case 'Title':
      value = title
      break
    case 'Password':
      value = password
      break
    default:
      break
  }
  const [state, setState] = useState(value)

  useEffect(() => {
    let setValue: (value: string) => void
    switch (name) {
      case 'Title':
        setValue = setTitle
        break
      case 'Password':
        setValue = setPassword
        break
      default:
        break
    }
    setValue!(state)
  }, [state, name, setTitle, setPassword])

  useEffect(() => {
    if (name === 'Password') return
    setState(title)
  }, [title, name])

  return (
    <div className='box'>
      <div className='input-wrapper'>
        <input
          type='text'
          id={`input_${name}`}
          className='form-control'
          placeholder={name}
          value={state}
          onChange={(e) => setState(e.currentTarget.value)}
        />
        <label htmlFor='input' className='control-label'>
          {name}
        </label>
      </div>
    </div>
  )
}

export default InputField
